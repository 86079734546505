import React from 'react';
import Layout from '../components/layout/Layout';
import { Link } from 'gatsby';
import "../style/styles.css"
import { BackButton } from '../components/ButtonDesign';

const XMP = () => (
  <Layout>
    <div className="px-4 md:px-32">
      <div className="w-full h-full py-12 md:py-24 mx-auto flex justify-center items-center">
        <div className='w-full text-xl md:text-3xl font-bold'>
          <p className="text-lg text-left border-b-2">XMP？</p>
          <div className="py-4 sm:p-8 text-sm md:text-xl text-left break-all">
            <p className="mb-2">XMP とは Monaparty 上の基軸トークンです。Monaparty とは Monacoin のブロックチェーンの上でトークンを発行したり出来るプラットフォームです。トークンを仕舞にはウォレットが必要です。以下では関連アプリを紹介します。</p>
            <p>XMP is Monaparty's main token. Monaparty is a platform that allows you to issue tokens on the Monacoin blockchain. You need a wallet to get tokens. The related apps are introduced below.</p>
            <div className="flex flex-wrap">
              <Card title={"Monaparty(Official)"} titleJP="もなぱーてぃー" desc="プラットフォーム / platform" link={"https://www.monaparty.me/"} />
              <Card title={"Monacard"} titleJP="もなかーど" desc="人気 No.1 アプリ / popular no.1 app" link={"https://card.mona.jp/"} />
              <Card title={"Mpurse"} titleJP="えむぱーす" desc="ウォレット / wallet" link={"https://blog.n-ista.org/2019/06/mpursegoogle-chromefirefox.html"} />
              <Card title={"Monapalette"} titleJP="もなぱれっと" desc="ウォレット / wallet" link={"https://anipopina.hateblo.jp/entry/monapalette-quickstart-guide"} />
              <Card title={"Monya"} titleJP="もにゃ" desc="ウォレット / wallet" link={"https://monya-wallet.github.io/"} />
              <Card title={"Ask Mona 3.0"} titleJP="あすくもなさんてんぜろ" desc={"クールな掲示板 / cool secret base"} link={"https://web3.askmona.org/"} />
              <Card title={"Monacute"} titleJP="もなきゅーと" desc={"AI生成されたNFTのオークションサイト / ai-generated nft auction site"} link={"https://monacute.art/"} />
              <Card title={"monacotto"} titleJP="もなこっと" desc="モナカードマーケットプレイス / monacard market place" link={"https://monacotto.monatoka.com/plaza"} />
              <Card title={"mona-tools"} titleJP="もなつーる" desc={"モナパーティーエクスプローラー / monaparty explorer"} link={"https://mona-tools.com/"} />
              <Card title={"monanosu"} titleJP="もなのす" desc={"モナーが暮らしてる / ( ´∀`)"} link={"https://monanosu.kotaroo.work/"} />
            </div>
          </div>
          <BackButton />
        </div>
      </div>
    </div>
  </Layout>
);

const Card = ({ title, titleJP, desc, link }) => {
  return (
    <Link
      to={ link }
      type="button"
      className="hover:opacity-75 rounded-xl border-4 shadow-lg mt-8 m-auto flex justify-center items-center"
      style={{
        borderColor: 'rgb(222, 242, 217)',
      }}
    >
      <div className="h-32 sm:h-40 w-64 p-4 m-auto sm:text-base flex justify-center items-center">
        <div className="text-center">
          <div>
            <p className="font-bold">{ title }</p>
            <p>{ titleJP }</p>
            <p>{ desc }</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default XMP;